export const parseOrderLineItems = orders =>
  orders.map((orderLine: any) => {
    return {
      type: "physical",
      name: orderLine.title,
      quantity: 1,
      unit_price: orderLine.price,
      total_amount: orderLine.price,
      total_tax_amount: orderLine.total_tax_amount,
      tax_rate: orderLine.tax_rate,
    }
  })

export const calculateTotalAmount = orderItems =>
  orderItems.reduce((acc: number, item: any) => acc + item.total_amount, 0)

export const calculateTotalTax = orderItems =>
  orderItems.reduce((acc: number, item: any) => acc + item.vat, 0)
