import * as React from "react"
import { useForm } from "react-hook-form"
import { GrMail } from "react-icons/gr"
// @ts-ignore
import styles from "./FormPage.module.css"

import { OrderInformation } from "./OrderInformation"
import { useEffect, useState } from "react"
import {
  calculateTotalAmount,
  calculateTotalTax,
  parseOrderLineItems,
} from "../../../netlify/utils"

export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export enum Order {
  "CART",
  "NORMAL",
  "COURSE",
  "MOPED",
}

const prettierPrint = state =>
  `Summa: ${state?.items?.reduce(
    (sum, item) => sum + parseInt(item?.price),
    0
  )} kr \n` + state?.items?.map(i => `${i?.title}: ${i?.price} \n`)

interface Props {
  orderState?: {
    items: {
      id: string
      sub: string
      title: string
      price: string
      vat: string
    }[]
  }
  orderType?: Order
  car?: boolean
  date?: string
}

const allItems = require("../../data/productDetailsArray.json")

function foldOrderItems(item) {
  // from json file, get all lineItems for a product and add them
  const productItem = allItems.find(
    i => i.sellerArticlePackageMasterId === item.id
  )
  return productItem.lineItems.map(lineItem => ({
    id: lineItem.sellerArticlePackageDetailId,
    name: lineItem.detailDescription,
    quantity: lineItem.amount,
    unit_price: toKlarnaFixed(lineItem.price),
    total_amount: toKlarnaFixed(lineItem.rowPrice),
    total_tax_amount: toKlarnaFixed(lineItem.vat),
    tax_rate: determineTaxRate(lineItem.accountDescription),
  }))
}

export const FormPage: React.FC<Props> = ({
  orderState,
  orderType,
  car,
  date: datetime,
}) => {
  const { register, handleSubmit } = useForm()
  const [submitted, setSubmitted] = React.useState(false)

  const orderLineItems = orderState?.items.flatMap(foldOrderItems)

  useEffect(() => {
    if (typeof window !== "undefined") {
      // @ts-ignore
      window.klarnaAsyncCallback = function() {
        // FETCH payment session and retrieve client_token
        fetch("/.netlify/functions/createSession", {
          method: "POST",
          body: JSON.stringify({
            orderLineItems,
          }),
        })
          .then(response => response.json())
          .then(data => {
            if (data.client_token) {
              // @ts-ignore
              Klarna.Payments.init(
                {
                  client_token: data.client_token,
                },
                function(init) {
                  init.load(
                    {
                      container: "#klarna-payments-container",
                      payment_method_category: "klarna",
                    },
                    function(res) {
                      console.debug(res)
                    }
                  )
                }
              )
            } else {
              // TODO: Handle error?
            }
          })
          .catch(error => {
            console.log("error", error)
          })
      }
    }
  }, [])

  const isTheoryCourse = orderType === Order.NORMAL || orderType === Order.MOPED

  const [hasBooking, setHasBooking] = useState(false)
  const [orderId, setOrderId] = useState(null)

  const orderInformation = () => {
    switch (orderType) {
      case Order.COURSE:
        return (
          <>
            <p>
              <strong> Detta gäller vid intensivkurs 2 veckor </strong>
            </p>
            <li>
              Avbokning sker senast två veckor innan kursstart så får du fullt
              betalt
            </li>
            <li>
              Avbokning 1 vecka innan kursstart så får du 50% av beloppet
              återbetalt
            </li>
            <li>Avbokning vid kursstart så blir du fullt debiterad</li>
            <li>Vid ogiltig frånvaro blir man fullt debiterad</li>
            <li>Vi drar 10% administrationsavgift vid all återbetalning</li>
            <li>
              Vid akut sjukdom så krävs läkarintyg, då bli du erbjuden plats vid
              en ny kursstart
            </li>
            <br />
          </>
        )

      case Order.NORMAL:
        return (
          <>
            <p>
              Avbokning sker senast 3 dagar innan kursen. <br />
              Om du är sjuk så ska du sjukanmäla det senast 1 timme före kursens
              start samt uppvisa ett läkarintyg för att du inte ska bli
              debiterad. <br />
              Betalning sker i förväg så fort du har fått bekräftelse på bokning
              via mejl, som allra senast 3 dagar innan kursstart
              <br />
            </p>
          </>
        )

      case Order.CART:
        return (
          <p>
            Betalning sker i förväg så fort du har fått bekräftelse på bokning
            via mejl
            <br />
          </p>
        )

      case Order.MOPED:
        return (
          <>
            <div className={styles.field}>
              <label htmlFor="mopedContact">
                Moped - Vårdnadshavares personnummer
              </label>
              <input type="text" name="mopedContact" />
            </div>
            <p>
              <input
                type="checkbox"
                required
                style={{
                  height: "24px",
                  width: "24px",
                  marginRight: "8px",
                }}
              />
              Jag som vårdnadshavare tillåter att eleven får ta AM-kort.
            </p>
            <p>
              Avbokning sker senast 3 dagar innan kursen. <br />
              Om du är sjuk så ska du sjukanmäla det senast 1 timme före kursens
              start samt uppvisa ett läkarintyg för att du inte ska bli
              debiterad. <br />
              Betalning sker i förväg så fort du har fått bekräftelse på bokning
              via mejl, som allra senast 3 dagar innan kursstart
              <br />
            </p>
          </>
        )

      default:
        return (
          <p>
            Om din förfrågan innehåller en beställning återkommer vi med en
            verifierad bekräftelse för din bokning samt betalningsalternativ.
          </p>
        )
    }
  }

  function handleOnKlarnaBuy(event) {
    event.preventDefault()
    const formData = {
      name: event.currentTarget.elements.name.value,
      personNumber: event.currentTarget.elements.personNumber.value,
      gearbox: event.currentTarget.elements?.gearbox?.value,
      mopedKontakt: event.currentTarget.elements?.mopedContact?.value,
    }

    if (typeof window !== "undefined" && orderLineItems) {
      const totalOrderAmount = calculateTotalAmount(orderLineItems)
      const totalTaxAmount = orderLineItems.reduce(
        (acc: number, item: any) => acc + item.total_tax_amount,
        0
      )

      const secondary_reference = formData.mopedKontakt
        ? `Vårdnadshavare:${formData.mopedKontakt}`
        : formData.name + " " + formData.gearbox

      const merchant_details = {
        merchant_data: formData,
        merchant_reference1: formData.personNumber + " " + datetime,
        merchant_reference2: secondary_reference,
      }
      console.log(merchant_details)

      // @ts-ignore
      window.Klarna.Payments.authorize(
        {
          payment_method_category: "klarna",
        },
        {
          purchase_country: "SE",
          purchase_currency: "SEK",
          locale: "sv-SE",
          order_amount: totalOrderAmount,
          order_tax_amount: totalTaxAmount,
          order_lines: orderLineItems,
          ...merchant_details,
        },
        function(res) {
          if (res.authorization_token) {
            // Create order with auth token
            fetch("/.netlify/functions/createOrder", {
              method: "POST",
              body: JSON.stringify({
                orderLineItems,
                auth_code: res.authorization_token,
                ...merchant_details,
              }),
            })
              .then(response => response.json())
              .then(data => {
                console.log("Order created: ", data.order_id)
                if (!data.order_id) {
                  throw new Error("could not create order!")
                }
                const formDataWithOrderId = {
                  ...formData,
                  Order: data.order_id,
                }

                const encodedFormData = encode({
                  "form-name": "orders",
                  ...formDataWithOrderId,
                })

                fetch("/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  body: encodedFormData,
                })
                  .then(() => {
                    setOrderId(data.order_id)
                    setSubmitted(true)
                  })
                  .catch(error => console.error(error))
              })
              .catch(error => {
                console.error("Error:", error)
              })
          } else {
            // TODO: handle if no auth token?
          }
        }
      )
    }
  }

  return submitted ? (
    <div style={{ height: 500, margin: "64px", padding: 32 }}>
      <h1>Tack, vi hörs!</h1>
      <h3>
        Vi återkommer till dig inom kort för att bekräfta din fråga eller
        beställning. Med önskan om gott samarbete, hälsar vi dig välkommen till
        oss på Amins Trafikskola!
      </h3>
      <p>Ordernummer: {orderId}</p>
      <p>
        <a href="https://www.facebook.com/albinstrafik/" className="fbLink">
          <img
            src={require("./../../images/FB.png")}
            alt="facebook"
            width="42"
          />
        </a>
        Gilla oss på Facebook för att få bra erbjudanden, tips om lediga
        körlektioner och annan viktig information!
      </p>
    </div>
  ) : (
    <form
      onSubmit={handleOnKlarnaBuy}
      name="orders"
      className={styles.formPage}
      data-netlify="true"
      netlify-honeypot="bot-field"
      method="post"
    >
      <input type="hidden" name="form-name" value="contact" />
      <h1>
        Fyll i för att boka <GrMail size={32} className={styles.headerIcon} />
      </h1>
      <div className={styles.field}>
        <label htmlFor="Namn">Namn</label>
        <input type="text" placeholder="Ditt fullständiga namn" name="name" />
      </div>
      <div className={styles.field}>
        <label htmlFor="Personnummer">Personnummer</label>
        <input
          type="text"
          placeholder="För beställning behövs ditt personnummer, även de 4 sista"
          name="personNumber"
        />
        <small>Format: YYYYMMDDXXXX</small>
      </div>
      {!true && (
        <>
          <div className={styles.field}>
            <label className={styles.checkbox}>
              Har du körkortstillstånd?{" "}
              <input
                type="checkbox"
                name="har_körkortstillstånd"
                ref={register({ required: false })}
                style={{ height: "24px", width: "24px", marginRight: "8px" }}
              />
            </label>
          </div>
          <div className={styles.field}>
            <label className={styles.checkbox}>
              Har du övningskört tidigare?{" "}
              <input
                type="checkbox"
                name="har_kört_tidigare"
                ref={register({ required: false })}
                style={{ height: "24px", width: "24px", marginRight: "8px" }}
              />
            </label>
          </div>{" "}
          <div className={styles.field}>
            <label className={styles.checkbox}>
              Har du fullfört Risk 1:an?{" "}
              <input
                type="checkbox"
                name="risk1"
                ref={register({ required: false })}
                style={{ height: "24px", width: "24px", marginRight: "8px" }}
              />
            </label>
          </div>
          <div className={styles.field}>
            <label className={styles.checkbox}>
              Har du fullfört Risk 2:an?{" "}
              <input
                type="checkbox"
                name="risk2"
                ref={register({ required: false })}
                style={{ height: "24px", width: "24px", marginRight: "8px" }}
              />
            </label>
          </div>
          <div className={styles.field}>
            <label>
              Tider som funkar bäst för mig (Ctrl/Cmd + klick för att markera
              flera):{" "}
              <select name="preferred_times[]" multiple>
                {car ? (
                  <>
                    <option value="10:00">10:00</option>
                    <option value="11:30">11:30</option>
                    <option value="13:30">13:30</option>
                    <option value="15:00">15:00</option>
                    <option value="16:40">16:40</option>
                  </>
                ) : (
                  <>
                    <option value="11:00">11:00</option>
                    <option value="13:30">13:30</option>
                    <option value="15:40">15:40</option>
                  </>
                )}
              </select>
            </label>
          </div>
          <div className={styles.field}>
            <label className={styles.checkbox}>
              Har du en bokad tid för uppkörning?
              <input
                type="checkbox"
                name="has_test_booked"
                ref={register({ required: false })}
                style={{ height: "24px", width: "24px", marginRight: "8px" }}
                onChange={e => {
                  setHasBooking(e.target.checked)
                }}
              />
            </label>

            <label
              style={
                !hasBooking
                  ? {
                      display: "none",
                    }
                  : {}
              }
            >
              <input
                type="date"
                ref={register({ required: false })}
                name="has_test_booked_date"
              />
            </label>
          </div>
        </>
      )}

      <div
        className={styles.radios}
        style={
          !car
            ? {
                display: "none",
              }
            : {}
        }
      >
        <label>Växellåda:</label>
        <label htmlFor="manuell">Manuell</label>
        <input
          type="radio"
          id="manuell"
          name="gearbox"
          value="Manuell"
          required={car}
        />
        <label htmlFor="automat">Automat</label>
        <input
          type="radio"
          id="automat"
          name="gearbox"
          value="Automat"
          required={car}
        />
      </div>
      <div className={styles.field}>
        <OrderInformation orderState={orderState} />
        <textarea
          value={prettierPrint(orderState)}
          name="Info"
          ref={register({ required: true })}
          style={{ display: "none" }}
        />
        {datetime}
      </div>
      {orderInformation()}

      <hr />
      <h3>Behandling av personuppgifter (GDPR)</h3>
      <p>
        <input
          type="checkbox"
          required
          style={{ height: "24px", width: "24px", marginRight: "8px" }}
        />
        Jag är införstådd med att för att boka kurser eller paket så låter jag
        Amins trafikskola hantera uppgifterna som hämtas in via formuläret, och
        godkänner den behandlingen av mina personuppgifter.
      </p>
      <p>
        <input
          type="checkbox"
          required
          style={{
            height: "24px",
            width: "24px",
            marginRight: "8px",
          }}
        />
        Jag accepterar våra köpvillkor och avbokningsregler, som du kan läsa
        här:
        <a
          href="/conditions"
          target="_blank"
          className="underline text-red-500 pl-2"
        >
          Köpvillkor
        </a>
      </p>
      <br />

      <div id="klarna-payments-container"></div>

      <p>
        <input
          type="submit"
          value="Boka och betala med Klarna"
          className="bg-black text-white p-3 max-w-xs mb-4 cursor-pointer"
        ></input>
        <img
          style={{ width: 100, display: "inline-flex", paddingLeft: 16 }}
          src={require("../../images/klarna.webp")}
          alt="klarna-logo"
        />
      </p>
    </form>
  )
}
function determineTaxRate(accountDescription: string) {
  if (accountDescription.includes("3011")) {
    return 2500
  } else if (accountDescription.includes("3013")) {
    return 600
  } else if (accountDescription.includes("3014")) {
    return 0
  } else {
    return 1200
  }
}
function toKlarnaFixed(rowPrice: number) {
  return rowPrice * 100
}
